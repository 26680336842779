import {
  createTheme,
  ThemeProvider as DefaultThemeProvider,
} from "@mui/material";
import { PropsWithChildren } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F1734",
    },
    secondary: {
        main: "#F2F2F2",
    }
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    allVariants: {
      color: "#0E0E0E",
    },
    button: {
        fontWeight: "bold"
    }
  },
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
                borderWidth: 2
            }
        }
    }
  }
});

const ThemeProvider = (props: PropsWithChildren) => {
  return (
    <DefaultThemeProvider theme={theme}>{props.children}</DefaultThemeProvider>
  );
};

export default ThemeProvider;
