import { useState } from "react";
import {
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import HeroImage from "../../assets/hero.png";
import LogoImage from "../../assets/logo.png";
import ConceptImage from "../../assets/concept.jpg";
import Modal from "../../components/Modal";
import styles from "./Home.module.scss";
import { Instagram } from "react-feather";

const Home: React.FC = () => {
  const [modalRegisterVisible, setModalRegisterVisible] = useState(false);
  const [modalDetailsVisible, setModalDetailsVisible] = useState(false);

  return (
    <>
      <section className={styles.Container}>
        <div className={styles.Logo}>
          <img className={styles.LogoImage} src={LogoImage} alt="logo" />
        </div>

        <div className={styles.HeroContainer}>
          <img
            src={HeroImage}
            className={styles.Hero}
            alt="ratatouille character"
          />
        </div>

        <div className={styles.Content}>
          <Typography
            variant="h3"
            color="#F2F2F2"
            sx={{ fontWeight: 600 }}
            gutterBottom
          >
            RATATOUILLE
          </Typography>

          <Typography
            variant="body2"
            color="#F2F2F2"
            sx={{ fontWeight: 800 }}
            gutterBottom
          >
            Step into the world of immersive cinema.
          </Typography>

          <Typography
            variant="body2"
            color="#F2F2F2"
            sx={{ fontWeight: 800 }}
            gutterBottom
          >
            With our film experience, you won't just watch the movie - you will
            taste it.
          </Typography>

          <Button
            variant="contained"
            style={{
              maxWidth: 400,
              width: "100%",
              marginTop: 12,
              marginBottom: 12,
            }}
            size="large"
            onClick={() => {
              setModalRegisterVisible(true);
            }}
          >
            BOOK A SPOT
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            style={{ maxWidth: 400, width: "100%", color: "white" }}
            size="large"
            onClick={() => {
              setModalDetailsVisible(true);
            }}
          >
            DETAILS
          </Button>
        </div>
      </section>

      <Modal
        title="Book"
        description="We will contact you shortly to confirm your booking."
        open={modalRegisterVisible}
        onClose={() => {
          setModalRegisterVisible(false);
        }}
      >
        <form
          action="https://formbold.com/s/35KM2"
          method="post"
          style={{ width: "100%" }}
        >
          <FormControl
            style={{ display: "flex", flexDirection: "column", rowGap: 20 }}
          >
            <TextField
              label="First Name"
              name="firstName"
              variant="outlined"
              placeholder="Alfredo"
              style={{ width: "100%" }}
              required
            />

            <TextField
              label="Last Name"
              name="lastName"
              variant="outlined"
              placeholder="Linguini"
              style={{ width: "100%" }}
              required
            />

            <TextField
              label="Phone Number"
              name="phone"
              variant="outlined"
              placeholder="99123123"
              style={{ width: "100%" }}
              required
            />

            <TextField
              label="Number of people"
              name="people"
              variant="outlined"
              type="number"
              style={{ width: "100%" }}
              defaultValue={1}
            />

            <Button type="submit" variant="contained">
              Submit
            </Button>
          </FormControl>
        </form>
      </Modal>

      <Modal
        title="The Concept"
        open={modalDetailsVisible}
        innerContainerStyle={{ paddingLeft: 30, paddingRight: 25 }}
        onClose={() => {
          setModalDetailsVisible(false);
        }}
      >
        <div>
          <img
            src={ConceptImage}
            style={{ width: "100%", borderRadius: 20, marginBottom: 10 }}
            alt="the ratatouille dish in the movie"
          />

          <Typography variant="body1" gutterBottom>
            Step into a world of immersive film experience, where every dish is
            served corresponding to a scene from the film.
          </Typography>

          <Typography variant="body1" gutterBottom>
            The ticket price is €70 p/p and includes:
            <ul>
              <li>a 6-course menu cooked by professional chefs</li>
              <li>half a bottle of wine + a bottle of water</li>
              <li>a unique cinematic and gastronomical experience</li>
            </ul>
          </Typography>

          <Typography variant="body1" gutterBottom>
            For more information and the menu, visit our instagram
          </Typography>

          <Link
            href="https://instagram.com/film_and_flavour"
            target="_blank"
            rel="noreferrer"
          >
            <Button style={{ width: "100%" }} startIcon={<Instagram />}>
              OPEN INSTAGRAM
            </Button>
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Home;
