import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import ThemeProvider from "./Theme";

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <header className="App-header"></header>

        <main className="App-main">
          <Home />
        </main>

        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
