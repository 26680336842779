import { IconButton, Link } from "@mui/material";
import { Instagram, PhoneCall } from "react-feather";
import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={styles.Container}>
      <div className={styles.Contact}>
        <Link href="tel:+35797927773">
          <IconButton
            size="large"
            color="primary"
            style={{ backgroundColor: "#7F1734" }}
          >
            <PhoneCall color="#FFF" />
          </IconButton>
        </Link>

        <Link
          href="https://www.instagram.com/film_and_flavour/"
          target="_blank"
          rel="noreferrer"
        >
          <IconButton
            size="large"
            color="primary"
            style={{ backgroundColor: "#7F1734" }}
          >
            <Instagram color="#FFF" />
          </IconButton>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
