import {
  Fade,
  IconButton,
  Modal as DefaultModal,
  ModalProps,
  Typography,
} from "@mui/material";
import { X } from "react-feather";

interface IProps extends ModalProps {
  title?: string;
  description?: string;
  innerContainerStyle?: React.CSSProperties;
  onClose?: () => void;
}

const Modal: React.FC<IProps> = (props) => {
  const { title, description, innerContainerStyle, onClose, ...rest } = props;

  return (
    <DefaultModal {...rest}>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={props.open}>
          <div
            style={{
              width: "80%",
              maxWidth: 400,
              minHeight: 200,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#FFF",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 12,
              paddingBottom: 20,
              overflowY: "auto",
              overflowX: "hidden",
              ...innerContainerStyle,
            }}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 50,
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              {title ? <Typography variant="h5">{title}</Typography> : null}

              {onClose ? (
                <IconButton
                  style={{ position: "absolute", right: 0, top: 2 }}
                  onClick={onClose}
                >
                  <X />
                </IconButton>
              ) : null}
            </div>

            {description ? (
              <Typography
                variant="body1"
                style={{ marginBottom: 10 }}
                align="center"
              >
                {description}
              </Typography>
            ) : null}

            {props.children}
          </div>
        </Fade>
      </div>
    </DefaultModal>
  );
};

export default Modal;
